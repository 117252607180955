// Override Bootstrap card variables

$card-spacer-y: 1.35rem;
$card-spacer-x: 1.35rem;

$card-cap-padding-y: 1rem;
$card-cap-padding-x: $card-spacer-x;

$card-border-radius: $border-radius;
$card-border-color: rgba($gray-900, 0.125);
$card-inner-border-radius: $border-radius;
$card-cap-bg: rgba($gray-900, 0.03);

// Theme variables for custom cards

$card-scrollable-max-height: 15rem;
