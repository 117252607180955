// Custom form control solid component
//
// Append the .form-control-solid class to a .form-control input
// to get a solid styling for the input

.form-control-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-border-color;

    &:focus {
        background-color: $input-solid-bg;
    }
}
