// Override Bootstrap buttons/forms shared variables

// Input and button variables for default size
$input-btn-padding-y: 0.875rem;
$input-btn-padding-x: 1.125rem;
$input-btn-font-size: $font-size-sm;
$input-btn-line-height: 1;

// Input and button variables for small size
$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: 0.75rem;
$input-btn-line-height-sm: 1;

// Input and button variables for large size
$input-btn-padding-y-lg: 1.125rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: 1;

// Override Bootstrap form variables

$input-bg: $white;

$input-color: $body-color;
$input-border-color: $gray-400;

$input-focus-border-color: transparent;

$input-placeholder-color: $gray-500;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $input-bg;

// Theme variables for solid form styling

$input-solid-bg: mix($gray-100, $gray-200, 75%);
$input-solid-border-color: $input-solid-bg;

// Theme variables for custom button sizes

// Extra small button sizing
$btn-padding-y-xs: 0.25rem;
$btn-padding-x-xs: 0.5rem;
$btn-font-size-xs: 0.7rem;
$btn-border-radius-xs: $border-radius;

// Extra large button sizing
$btn-padding-y-xl: 1.25rem;
$btn-padding-x-xl: 1.5rem;
$btn-font-size-xl: 1.125rem;
$btn-border-radius-xl: $border-radius-lg;

// Marketing button variant
$btn-padding-x-marketing: 1.5rem;
$btn-padding-y-marketing: 0.8rem;
$btn-height-marketing: 3.125rem;
$btn-font-size-marketing: 0.7rem;
