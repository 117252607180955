.shadow-right-sm {
    box-shadow: $box-shadow-right-sm !important;
}

.shadow-right {
    box-shadow: $box-shadow-right !important;
}

.shadow-right-lg {
    box-shadow: $box-shadow-right-lg !important;
}

.shadow-left-sm {
    box-shadow: $box-shadow-left-sm !important;
}

.shadow-left {
    box-shadow: $box-shadow-left !important;
}

.shadow-left-lg {
    box-shadow: $box-shadow-left-lg !important;
}
