// Global scroll target offest for use
// with the sticky nav

:target:before {
    content: '';
    display: block;
    height: 5.125rem;
    margin: -5.125rem 0 0;
}

// Alternative to scroll target offset above
// Awaiting Safari and iOS Safai support

// :target {
//     scroll-margin-top: 5.125rem;
// }

.nav-sticky {
    @extend .sticky-top;
    top: $topnav-base-height + $grid-gutter-width;
    font-size: 0.9rem;

    .nav-link {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .nav-link.active {
        font-weight: 600;
    }

    ul.nav > li.nav-item > .nav {
        display: none;
    }

    ul.nav > li.nav-item > a.nav-link.active ~ .nav {
        display: flex;
    }
}
