.badge-sm {
    font-size: 0.75rem;
}

.badge-md {
    font-size: 1rem;
}

.badge-lg {
    font-size: 1.25rem;
}
